<template>
  <div
    class="container-buttons-responsive"
    v-if="isResponsive"
  >
    <v-btn
      @click="openDialogAcceptOrder"
      elevation="0"
      class="button-confirm mon-bold"
      style="width: 100%;"
    >
      {{ texts.orders.buttonConfirm }}
    </v-btn>
    <v-btn
      @click="openDialogRejectOrder"
      elevation="0"
      class="button-reject mon-regular mt-5"
      style="width: 100%;"
    >
      {{ texts.orders.buttonReject }}
    </v-btn>
  </div>
  <div
    v-else
    class="container-buttons"
  >
    <div class="display-flex align-items-center">
      <v-btn
        @click="openDialogRejectOrder"
        elevation="0"
        class="button-reject mon-regular"
      >
        {{ texts.orders.buttonReject }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="openDialogAcceptOrder"
        elevation="0"
        class="button-confirm mon-bold"
      >
        {{ texts.orders.buttonConfirm }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderInProcessAcceptLayout",
  props: {
    texts: {
      type: Object,
    },
    salesOrderId: {
      type: String,
    },
  },
  data() {
    return {
      //VARIABLES
      bLoadingReject: false,
      bLoadingConfirm: false,
      isResponsive: false,
    };
  },
  beforeMount() {},
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    openDialogAcceptOrder: function () {
      this.$store.commit("setDialogAcceptOrder", {
        active: true,
        purchaseOrderId: this.$route.params.id,
        idSalesOrder: this.salesOrderId,
      });
    },
    openDialogRejectOrder: function () {
      this.$store.commit("setDialogRejectOrder", {
        active: true,
        purchaseOrderId: this.$route.params.id,
        idSalesOrder: this.salesOrderId,
      });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {},
};
</script>

<style scoped>
.button-reject {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #f50017;
  background-color: #ffffff !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #f50017;
  border-radius: 10px;
  height: 40px !important;
  opacity: 1;
  text-transform: initial;
}

.button-confirm {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  background: transparent linear-gradient(256deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  height: 40px !important;
  opacity: 1;
  text-transform: initial;
}

.container-buttons {
  position: fixed;
  bottom: 0;
  width: calc(100% - 80px);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -3px 6px #00000029;
  opacity: 1;
  padding: 15px;
  z-index: 1;
}

.container-buttons-responsive {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -3px 6px #00000029;
  opacity: 1;
  padding: 15px;
  z-index: 1;
}
</style>